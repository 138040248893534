<template>
  <div>
    <h2 class="content-block">
      <span v-if="history">Riwayat </span>Laporan Audit {{ title }}
    </h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="permissions.unfilteredData"
    ></filter-table-unit-bisnis>

    <div class="input-group content-block">
      <div
        class="dx-card"
        style="justify-content: center; padding: 10px"
        v-if="permissions.canVerified && !auditee"
      >
        <div style="justify-content: center; display: flex">
          <b>Filter Status Laporan Audit</b>
        </div>
        <div style="justify-content: center; display: flex">
          <DxRadioGroup
            :items="statusFilterOptions"
            value-expr="key"
            display-expr="name"
            v-model:value="valueStatus"
            layout="horizontal"
            @valueChanged="handleRefresh()"
          >
          </DxRadioGroup>
        </div>
      </div>
    </div>
    <br />
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @content-ready="onContentReady"
    >
      <DxSelection mode="single" />
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="130" data-field="Action">
        <DxButton
          v-if="permissions.canUpdate && !auditee"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="fieldChooser"
        />
        <DxButton
          v-if="permissions.canDelete && !auditee"
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
        />
        <DxButton
          v-if="permissions.canVerified && !auditee"
          hint="verifikasi"
          icon="fa fa-check-to-slot"
          @click="verification"
        />"
        <DxButton
          v-if="permissions.canCreate && !auditee"
          hint="upload implementasi before"
          icon="fa fa-upload"
          @click="uploadImplBefore"
        />"
        <DxButton
          hint="detail"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
      </dx-column>
      <dx-column :width="150" data-field="report_number" caption="No Capa" />
      <dx-column
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <dx-column
        data-field="section_id"
        caption="Section"
        :customize-text="sectionName"
      />
      <dx-column
        data-field="branch_id"
        caption="Cabang"
        :customize-text="branchName"
      />
      <dx-column
        :width="120"
        data-field="finding_type"
        caption="Jenis Temuan"
        :allow-sorting="false"
      />
      <dx-column data-field="finding_detail_type" caption="Detail Temuan" />
      <dx-column
        data-field="deadline"
        caption="Deadline"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="status"
        caption="Status"
        cell-template="grid-cell"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            green: data.value == 'Close',
            red: data.value == 'Open' || data.value == 'Dikembalikan',
            yellow: data.value == 'Pending',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column data-field="approved_by" caption="Diverifikasi Oleh" />
      <dx-column
        data-field="approved_date"
        caption="Tanggal Diverifikasi"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="audit_date"
        caption="Tanggal Dibuat"
        :customize-text="dateColumn"
      />
      <dx-column
        :width="120"
        data-field="audit_plan.start_date"
        caption="Tanggal Audit"
        :customize-text="dateColumn"
        :allow-sorting="false"
      />
      <dx-column
        field="status_audit"
        caption="SLA"
        :calculate-cell-value="calculateStatusAudit"
      />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: auditReport }">
        <masterDetailAuditReport
          :roles="roles"
          :history="history"
          :auditeeProps="auditee"
          :auditReport="auditReport.data"
          @refresh-audit="handleRefresh"
        ></masterDetailAuditReport>
      </template>
    </dx-data-grid>

    <!-- popup verification -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Verifikasi Laporan Audit"
      :drag-enabled="false"
      v-model:visible="popupVerif"
    >
      <template #content="{}">
        <b>Pastikan laporan audit sudah dicek!</b>
        <div class="popup-property-details">
          <div>
            <form
              action="/editVerificationReport"
              @submit.prevent="confirmVerif"
            >
              <DxSelectBox
                class="custom-form"
                :data-source="statusOptions"
                label="Pilih Status"
                v-model="verifData.status"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxSelectBox>
              <DxTextArea
                class="custom-form"
                label="Alasan"
                :height="90"
                :input-attr="{ 'aria-label': 'Notes' }"
                v-model:value="verifData.reason"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxTextArea>
              <div class="justify-center">
                <DxButtonx
                  class="customBtn"
                  text="Simpan"
                  icon="save"
                  type="success"
                  :use-submit-behavior="true"
                />
                <DxButtonx
                  class="customBtn"
                  text="Batal"
                  icon="close"
                  type="danger"
                  @click="cancle"
                />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup detail -->
    <DxPopup
      :width="popupWidth"
      :height="popupHeight"
      :show-title="true"
      title="Detail Laporan Audit"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Audit</div>
                  <div class="dx-field-value">
                    :
                    {{
                      dateColumn({ value: detailData.audit_plan.start_date })
                    }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Laporan</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.audit_date }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Nomor Capa</div>
                  <div class="dx-field-value">
                    : {{ detailData.report_number }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Jenis Temuan</div>
                  <div class="dx-field-value">
                    : {{ detailData.finding_type }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Faktor Temuan</div>
                  <div class="dx-field-value">
                    : {{ detailData.factor_finding }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Standard Penyimpangan</div>
                  <div class="dx-field-value">
                    : {{ detailData.aberration_standart }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Klasifikasi Kesalahan</div>
                  <div class="dx-field-value">
                    : {{ detailData.fault_classification }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Type Kesalahan</div>
                  <div class="dx-field-value">
                    : {{ detailData.fault_type }}
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Tipe Risiko Temuan</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_finding }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Status</div>
                  <div class="dx-field-value">
                    :
                    <span
                      :class="{
                        green: detailData.status == 'Close',
                        red:
                          detailData.status == 'Open' ||
                          detailData.status == 'Dikembalikan',
                        yellow: detailData.status == 'Pending',
                      }"
                      >{{ detailData.status }}</span
                    >
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Catatan</div>
                  <div
                    class="dx-field-value"
                    :class="{
                      'background-warning':
                        detailData.reason &&
                        detailData.status == 'Dikembalikan',
                    }"
                  >
                    : {{ detailData.reason || "-" }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">SLA</div>
                  <div>: {{ calculateStatusAudit(detailData) }}</div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Detail Temuan</div>
                  <div class="dx-field-value">
                    : {{ detailData.finding_detail_type }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Detail Temuan dan Refrensi</div>
                  <div
                    class="dx-field-value"
                    v-if="detailData.audit_group_finding.length > 0"
                  >
                    <div
                      v-for="(data, index) in detailData.audit_group_finding"
                      :key="data.id"
                    >
                      <div>
                        <span>{{ index + 1 }}. </span
                        >{{ data.finding_desc }} ({{ data.refrensi }})
                      </div>
                    </div>
                  </div>
                  <div class="dx-field-value text-grey" v-else>No data.</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Bukti Objektif</div>
                  <div class="dx-field-value">
                    : {{ detailData.proof_objective }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Deadline CAPA</div>
                  <div class="dx-field-value">: {{ detailData.deadline }}</div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">
                    <b>Metode 5 Why</b>
                    <DxList
                      :data-source="auditRootCause"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <p>
                          <span>{{ index + 1 }}. </span>{{ data.question }}
                        </p>
                      </template>
                    </DxList>
                  </div>
                  <div class="dx-field-value">
                    <b>Analisa Akar Masalah</b>
                    <DxList
                      :data-source="auditRootCause"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <p>
                          <span>{{ index + 1 }}. </span>{{ data.answer }}
                        </p>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Tindakan Perbaikan Sementara</div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="auditPrevective"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <p>
                          <span>{{ index + 1 }}. </span>{{ data.action }}
                        </p>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Tindakan Perbaikan Permanen</div>
                  <div class="dx-field-value">
                    <DxList
                      :data-source="auditCorrection"
                      item-template="list-item"
                    >
                      <template #list-item="{ data, index }">
                        <p>
                          <span>{{ index + 1 }}. </span>{{ data.action }}
                        </p>
                      </template>
                    </DxList>
                  </div>
                </div>
              </div>
              <div class="dx-card responsive-paddings">
                <div class="title-detail">Bukti Implementasi</div>
                <div v-for="data in auditImplBefore" :key="data.id">
                  <div class="dx-card responsive-paddings">
                    <div class="dx-field">
                      <div class="dx-field-label">Kondisi Before</div>
                      <div class="dx-field-value">
                        <div>
                          :
                          {{ data.proof_file }}
                        </div>
                      </div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label"></div>
                      <div class="dx-field-value">
                        <DxButtonx
                          hint="Bukti Before"
                          icon="fa fa-download"
                          text="Bukti"
                          type="default"
                          style="margin: 5px"
                          @click="displayPdf(data.url_file)"
                        />
                        <DxButtonx
                          v-if="permissions.canUpdate && !auditee"
                          hint="edit bukti before"
                          icon="fa fa-pen-to-square"
                          text="Edit"
                          type="default"
                          style="margin: 5px"
                          @click="editImplBefore(data)"
                        />
                      </div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label">Deskripsi</div>
                      <div class="dx-field-value">
                        <div>
                          :
                          {{ data.desc || "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="data in auditImplAfter" :key="data.id">
                  <div class="dx-card responsive-paddings">
                    <div class="dx-field">
                      <div class="dx-field-label">Kondisi After</div>
                      <div class="dx-field-value">
                        <div>
                          :
                          {{ data.proof_file }}
                        </div>
                      </div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label"></div>
                      <div class="dx-field-value">
                        <DxButtonx
                          hint="Bukti After"
                          icon="fa fa-download"
                          text="Bukti"
                          type="default"
                          class="customBtn"
                          @click="displayPdf(data.url_file)"
                        />
                      </div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label">Deskripsi</div>
                      <div class="dx-field-value">
                        <div>
                          :
                          {{ data.desc || "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup export -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Konfirmasi Export Laporan Audit"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupExport"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <div>
            <form action="/editVerificationReport" @submit.prevent="onExport()">
              <div>Pilih tanggal agenda audit</div>
              <DxDateRangeBox
                class="custom-form"
                :multi-view="false"
                :show-clear-button="true"
                display-format="d / MM / yyyy"
                v-model:value="currentValueExport"
                ><DxValidator>
                  <DxRequiredRule message="Required" /> </DxValidator
              ></DxDateRangeBox>

              <div class="justify-center">
                <DxButtonx
                  v-if="!loading"
                  class="customBtn"
                  text="Export"
                  icon="export"
                  type="default"
                  :use-submit-behavior="true"
                />
                <DxLoadIndicator v-else class="custom-form" />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
        <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
  DxSelection,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxList, DxItem } from "devextreme-vue/list";
import masterDetailAuditReport from "@/components/audit/masterDetailAuditReport.vue";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxDateRangeBox from "devextreme-vue/date-range-box";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    DxScrollView,
    DxList,
    DxItem,
    masterDetailAuditReport,
    FilterTableUnitBisnis,
    DxSelectBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    DxRadioGroup,
    DxDateRangeBox,
    Workbook,
    saveAs,
    DxLoadIndicator,
    DxSelection,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }
          // handle save key search
          vthis.searchValue(
            loadOptions.filter ? loadOptions.filter[0].filterValue : ""
          );

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            company: vthis.company || "",
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "audit-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupDetail: false,
      popupDelete: false,
      popupVerif: false,
      detailData: {},
      deleteData: {},
      verifData: {},
      auditRootCause: [],
      auditPrevective: [],
      auditCorrection: [],
      auditImplBefore: [],
      auditImplAfter: [],
      company: "",
      department: "",
      title: "",
      statusOptions: ["Dikembalikan", "Close"],
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      popupWidth: "60%",
      popupHeight: "90%",
      statusFilterOptions: [
        { key: "Pending,Open,Dikembalikan", name: "All" },
        { key: "Pending", name: "Verifikasi" },
        { key: "Open,Dikembalikan", name: "Proses" },
      ],
      valueStatus: "Pending,Open,Dikembalikan",
      popupExport: false,
      currentValueExport: [],
      loading: false,
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  mounted() {
    if (this.getSearchAuditReport !== "") {
      this.dataGrid.option("searchPanel.text", this.getSearchAuditReport || "");
    }
    // start responsive popup detail
    window.addEventListener("resize", this.updatePopupSize);
    this.updatePopupSize();
    // end
  },
  beforeDestroy() {
    // start responsive popup detail
    window.removeEventListener("resize", this.updatePopupSize);
    // end
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("auditReport", ["getAuditPlan", "getSearchAuditReport"]),
    ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("branch", ["getAllBranch"]),
    ...mapGetters("section", ["getAllSection"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  props: {
    roles: {
      type: String,
    },
    history: {
      type: Boolean,
      default: false,
    },
    auditee: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelectionChanged(e) {
      const selectedRowKeys = e.component.getSelectedRowKeys();

      if (selectedRowKeys.length > 0) {
        const selectedRowKey = selectedRowKeys[0]; // get key row selected
        e.component.collapseAll(-1);
        e.component.expandRow(selectedRowKey); // open detail table
      } else {
        console.log("ERROR");
      }
    },
    onContentReady(e) {
      const rowCount = e.component.totalCount();
      if (rowCount > 0 && !e.component.getSelectedRowKeys().length) {
        e.component.selectRowsByIndexes(0);
        const firstRowKey = e.component.getVisibleRows()[0].data.id; // getID first to open otomatic
        e.component.expandRow(firstRowKey); // open detail table
      }
    },
    calculateStatusAudit(rowData) {
      const auditDate = new Date(rowData.audit_date);
      const currentDate = new Date();
      const timeDifference = currentDate - auditDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      // Jika lebih dari 30 hari dan status bukan 'closed', maka Delay
      if (daysDifference > 30 && rowData.status !== "Closed") {
        return "Delay";
      }
      // Jika kurang dari atau sama dengan 30 hari dan status 'closed', maka On time
      else if (daysDifference <= 30 && rowData.status === "Closed") {
        return "On time";
      }
      // Jika kurang dari atau sama dengan 30 hari dan status belum 'closed', maka On time
      else if (daysDifference <= 30 && rowData.status !== "Closed") {
        return "On time";
      }
      // Jika lebih dari 30 hari dan status sudah 'closed', maka On time
      else if (daysDifference > 30 && rowData.status === "Closed") {
        return "On time";
      }
      return "-"; // Return empty if conditions are not met
    },
    async onExport() {
      this.loading = true;

      // Format date timestamp
      const startday = String(this.currentValueExport[0].getDate());
      const startmonth = String(this.currentValueExport[0].getMonth() + 1);
      const startyear = this.currentValueExport[0].getFullYear();
      const start = `${startyear}-${startmonth}-${startday} 00:00:00`;

      const endday = String(this.currentValueExport[1].getDate());
      const endmonth = String(this.currentValueExport[1].getMonth() + 1);
      const endyear = this.currentValueExport[1].getFullYear();
      const end = `${endyear}-${endmonth}-${endday} 23:59:00`;
      // end

      // get Audit Report by audit date
      const text = `?limit=999999&plan_date=${start},${end}`;
      const auditReport = await this.$store.dispatch(
        "auditReport/getAuditReport",
        text
      );
      const data = auditReport.data;

      if (data.length < 1) {
        notify("data tidak ditemukan", "warning", 2000);
        this.loading = false;
        return;
      } else {
        // menyisipkan data yang dibutuhkan (method)
        data.forEach((row) => {
          row.status_audit = this.calculateStatusAudit(row);
        });

        // Membuat workbook baru
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Audit Data");

        // Definisikan kolom secara dinamis
        const columns = [
          { header: "No Capa", key: "report_number" },
          {
            header: "Department",
            key: "department_id",
            transformer: (id) => this.departmentName({ value: id }),
          },
          {
            header: "Section",
            key: "section_id",
            transformer: (id) => this.sectionName({ value: id }),
          },
          {
            header: "Cabang",
            key: "branch_id",
            transformer: (id) => this.branchName({ value: id }),
          },
          { header: "Jenis Temuan", key: "finding_type" },
          { header: "Faktor Temuan", key: "factor_finding" },
          { header: "Standard Penyimpangan", key: "aberration_standart" },
          { header: "Klasifikasi Kesalahan", key: "fault_classification" },
          { header: "Type Kesalahan", key: "fault_type" },
          { header: "Tipe Risiko Temuan", key: "risk_finding" },
          { header: "Tipe Detail Temuan", key: "finding_detail_type" },
          { header: "Status", key: "status" },
          { header: "Di Verifikasi", key: "approved_by" },
          { header: "Tanggal Diverifikasi", key: "approved_date" },
          { header: "Tanggal Audit", key: "audit_plan" },
          { header: "Tanggal Dibuat", key: "audit_date" },
          { header: "Deadline", key: "deadline" },
          { header: "SLA", key: "status_audit" },
          { header: "Catatan", key: "reason" },
          { header: "Detail Temuan dan Refrensi", key: "audit_group_finding" },
          { header: "Bukti Objektif", key: "proof_objective" },
        ];

        // Tambahkan header
        worksheet.columns = columns.map((col) => ({
          header: col.header,
          key: col.key,
          width: 20, // Lebar kolom default
        }));

        // Terapkan gaya untuk header
        const headerRow = worksheet.getRow(1); // Baris pertama sebagai header
        headerRow.eachCell((cell) => {
          cell.font = {
            bold: true, // Ubah font menjadi bold
          };
          cell.alignment = {
            horizontal: "center", // Teks rata tengah secara horizontal
            vertical: "middle", // Teks rata tengah secara vertikal
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        data.forEach((item) => {
          // Hitung jumlah total audit_group_finding untuk menambahkan garis
          const totalFindings = item.audit_group_finding?.length || 1; // Minimal 1 baris untuk data utama

          // Baris awal untuk data ini menambahkan garis
          const startRow = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;

          // Tambahkan data utama di baris pertama
          const mainRowData = {};
          columns.forEach(({ key, transformer }) => {
            mainRowData[key] = transformer ? transformer(item[key]) : item[key];
          });

          // Tambahkan `Temuan A` ke baris utama jika ada
          mainRowData["audit_group_finding"] =
            item.audit_group_finding?.[0]?.finding_desc || "";
          mainRowData["audit_plan"] = item.audit_plan.start_date || "";

          const mainRow = worksheet.addRow(mainRowData);

          // Tambahkan data detail untuk `Temuan B` dan seterusnya
          if (item.audit_group_finding?.length > 1) {
            item.audit_group_finding.slice(1).forEach((detail) => {
              const detailRowData = {
                audit_group_finding: detail.finding_desc,
              };
              worksheet.addRow(detailRowData);
            });
          }

          // Tambahkan border pada setiap cell dari startRow ke totalFindings
          for (
            let rowIndex = startRow;
            rowIndex < startRow + totalFindings;
            rowIndex++
          ) {
            const row = worksheet.getRow(rowIndex);
            columns.forEach(({ key }) => {
              const cell = row.getCell(key);
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });

        // export file excle
        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `Laporan Audit (${start})(${end}).xlsx`
            );
            // close popup
            this.popupExport = false;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e, "error");
            notify(e.message, "error", 2000);
          });
      }
    },
    formatDate(params) {
      if (!params) return "";
      const day = String(params.getDate());
      const month = String(params.getMonth() + 1);
      const year = params.getFullYear();
      return `${year}-${month}-${day} 00:00:00`;
    },
    updatePopupSize() {
      this.popupWidth = window.innerWidth <= 600 ? "95%" : "60%";
    },
    editImplBefore(params) {
      this.$store.dispatch(
        "auditImplementation/setAuditImplementation",
        params
      );
      router.push(`/audit-impl/form/edit/${params.id}/before`);
    },
    uploadImplBefore(params) {
      router.push(`/audit-impl/form/add/${params.row.key}/before`);
    },
    searchValue(params) {
      this.$store.dispatch("auditReport/setSearchAuditReport", params);
    },
    handleRefresh(params) {
      this.dataGrid.refresh();
    },
    displayPdf(params) {
      window.open(params, "_blank");
    },
    confirmVerif() {
      this.verifData.approved_by = this.getUserDetail.full_name;
      this.verifData.approved_date = new Date();

      this.$store
        .dispatch("auditReport/editAuditReport", this.verifData)
        .then((res) => {
          notify("verifikasi success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupVerif = false;
    },
    cancle() {
      this.verifData = {};
      this.popupVerif = false;
    },
    verification(params) {
      if (params.row.data.status != "Pending") {
        notify("tunggu laporan audit di proses auditee", "warning", 2000);
        return;
      }

      this.verifData = params.row.data;
      this.popupVerif = true;
      this.verifData.status = "";
    },
    cellTemplate(container, options) {
      let value = options.value;
      let content = "";

      value.forEach((res, index) => {
        content += `<li style="
          margin: 0;
          padding: 2px 0;
          white-space: normal;
          overflow-wrap: break-word;
        ">${index + 1}. ${res.finding_desc} ( ${res.refrensi} )</li>`;
      });

      container.innerHTML = `<ul style="padding:0;margin:0">${content}</ul>`;
    },
    getCompany(value) {
      this.company = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    viewDetail(params) {
      this.popupDetail = true;

      this.detailData = params.row.data;
      this.getRelationReport(params.row.data.id);
    },
    async getRelationReport(params) {
      // get audit root couse
      const getRootCouse = await this.$store.dispatch(
        "auditRootCause/getAuditRootCause",
        `?limit=999&report=${params}`
      );
      this.auditRootCause = getRootCouse.data;
      // get audit correction
      const getAuditCorrection = await this.$store.dispatch(
        "auditCorrection/getAuditCorrection",
        `?limit=999&report=${params}`
      );
      this.auditCorrection = getAuditCorrection.data;
      // get audit prevective
      const getAuditPrevective = await this.$store.dispatch(
        "auditPrevective/getAuditPrevective",
        `?limit=999&report=${params}`
      );
      this.auditPrevective = getAuditPrevective.data;
      // get audit impl before
      const getAuditImplBefore = await this.$store.dispatch(
        "auditImplementation/getAuditImplementation",
        `?limit=999&report=${params}&type=Before`
      );
      this.auditImplBefore = getAuditImplBefore.data;
      // get audit impl after
      const getAuditImplAfter = await this.$store.dispatch(
        "auditImplementation/getAuditImplementation",
        `?limit=999&report=${params}&type=After`
      );
      this.auditImplAfter = getAuditImplAfter.data;
    },
    dateColumn(cellInfo) {
      if (cellInfo.value == null) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    branchName(cellInfo) {
      const location = this.getAllBranch.find(
        (res) => res.location_id == cellInfo.value
      );
      if (!location) {
        return "-";
      }
      return `${location.location_name}`;
    },
    sectionName(cellInfo) {
      const section = this.getAllSection.find(
        (res) => res.section_id == cellInfo.value
      );
      if (!section) {
        return "-";
      }
      return `${section.section_name}`;
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditReport/destroyAuditReport", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    fieldChooser(params) {
      this.$store.dispatch("auditReport/setAuditReport", params.row.data);
      router.push(`/audit-report/form/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissions.canCreate && !vThis.auditee) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch("auditReport/setAuditReportStatus", {
                action: "Add",
                roles: vThis.roles,
              });
              router.push(`/audit-report/form`);
            },
          },
        });
      }
      if (
        this.permissions.canCreate &&
        this.permissions.canUpdate &&
        this.permissions.unfilteredData
      ) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "export",
            // text: "Reports",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.popupExport = true;
            },
          },
        });
      }

      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.permissions.unfilteredData == false) {
        if (this.auditee) {
          params.auditee = this.getUserDetail.department_id || "-";
          this.title =
            `${this.getUserDetail.department_name} - ${this.getUserDetail.location_name} ` ||
            "-";
          params.branch = this.getUserDetail.location_id || "-";
        } else if (this.history) {
          params.my_history = this.getUserDetail.department_id || "-";
          params.branch = this.getUserDetail.location_id || "-";
          this.title = this.getUserDetail.department_name || "-";
          params.status = "Close";
        }
      } else {
        if (this.auditee) {
          params.status = "Open,Dikembalikan";
        } else if (this.history) {
          params.status = "Close";
        } else {
          params.status = this.valueStatus;
          console.log(this.valueStatus, "status filter");

          // params.status = "Open,Dikembalikan,Pending";
        }
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditReport/getAuditReport",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };

      return await datax;
    },
  },
};
</script>
        <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.dx-list-item-content {
  white-space: normal;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.green {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.red {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.yellow {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.array-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: normal;
  overflow-wrap: break-word;
}

.array-list li {
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.img-custom {
  width: 50%;
  height: auto;
}
.img-custom:hover {
  width: 100%;
  height: auto;
}
.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
}
.img-center {
  justify-content: center;
  display: flex;
}
.dx-field-label {
  white-space: normal;
}
.title-detail {
  text-align: start;
  font-weight: bold;
  margin-bottom: 10px;
}
.background-warning {
  background-color: #fff766;
  color: black;
  border-radius: 5px;
  padding: 5px 0px;
}
.text-grey {
  color: rgb(184, 176, 176);
  margin-left: 10px;
}
</style>