<template>
  <div>
    <h2 class="content-block">Document Preview</h2>
    <DxButton
      class="content-block"
      type="default"
      icon="back"
      hint="kembali"
      @click="back"
    />
    <br />
    <br />
    <!-- <table style="width: 90%; margin: auto">
      <tr>
        <th rowspan="4" style="width: 200px">
          <img src="../../../public/ljr-icon.png" width="200" height="150" />
        </th>
        <th
          colspan="4"
          style="
            height: 40;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          "
        >
          {{ titleDocType }}
        </th>
      </tr>
      <tr>
        <td
          colspan="4"
          style="
            height: 40;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          "
        >
          {{ getDocument.document_name }}
        </td>
      </tr>
      <tr style="font-weight: bold">
        <td>No. Dokumen</td>
        <td>No. Revisi</td>
        <td>Tanggal Ditetapkan</td>
        <td>Tanggal Kadaluarsa</td>
      </tr>
      <tr>
        <td>{{ getDocument.document_number }}</td>
        <td>{{ getDocumentFile.revision_number }}</td>
        <td>{{ formatDate(getDocumentFile.start_date) }}</td>
        <td>{{ formatDate(getDocumentFile.end_date) }}</td>
      </tr>
    </table>
    <br />
    <table style="width: 90%; margin: auto">
      <tr>
        <td>
          <b>Catatan Revisi :</b>
          <div v-html="getDocumentFile.revision_note"></div>
        </td>
      </tr>
    </table>
    <br /> -->
    <div style="display: flex; justify-content: center">
      <iframe
        height="550"
        width="98%"
        :src="
          getDocumentFile.files_url != null ? getDocumentFile.files_url : null
        "
        frameborder="0"
      ></iframe>
    </div>
    <div v-if="showOverlay" class="overlay">
      <div class="overlay-text">Screenshot not allowed</div>
    </div>
  </div>
</template>
<script>
import DxButton from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showOverlay: false,
    };
  },
  computed: {
    ...mapGetters("docFile", ["getDocumentFile"]),
    ...mapGetters("document", ["getDocument"]),
    titleDocType() {
      if (this.getDocument.document_type == "SOP") {
        return "STANDARD OPERATING PROCEDURE";
      } else if (this.getDocument.document_type == "WI") {
        return "WORKING INSTRUCTION";
      } else {
        return this.getDocument.document_type;
      }
    },
  },
  mounted() {
    // Show overlay when the user presses Win + PrtSc
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (
        (event.keyCode === 44 && event.metaKey) ||
        event.keyCode === "PrintScreen" ||
        event.metaKey ||
        event.keyCode === 91
      ) {
        // 44 is the PrtSc key, metaKey checks for Win key
        this.showOverlay = true;

        // Hide overlay after some time (e.g., 3 seconds)
        setTimeout(() => {
          this.showOverlay = false;
        }, 3000); // 3000 milliseconds = 3 seconds
      }
    },
    formatDate(params) {
      if (params == null) {
        return "-";
      }
      const date = new Date(params);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    back() {
      this.$store.dispatch("docFile/cleanDoc");
      this.$router.back();
    },
  },
  components: {
    DxButton,
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 2px solid black;
  border-collapse: collapse;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* semi-transparent black overlay */
  z-index: 9999;
}

.overlay-text {
  color: white;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>